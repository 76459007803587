<template>
  <v-app>
    <navigation :color="color" :flat="flat" />
    <v-main class="pt-0">
      <home />
      <about />
      <!--
      <download />
      <pricing />
      -->
      <contact />
    </v-main>

    <v-scale-transition>
      <div class="collabear" v-show="scrollYPos > 1300" @click="showChat = true">
        <img class="collabear-img" @mouseover="showBubble = true"
          src="https://assets.br-gru-1.linodeobjects.com/landing-page/collabear.webp" />
        <div class="chat-bubble elevation-7" v-show="showBubble && scrollYPos > 1500">
          <v-icon class="close-chat-bubble" @click.stop="closeChatBubble">mdi-close</v-icon>
          <p>{{ collabearMsg }}</p>
        </div>
      </div>
    </v-scale-transition>

    <div class="response-bubble elevation-7" v-show="showChat && showBubble && scrollYPos > 1500">
      <input style="outline: none" v-model="userInput" placeholder="digite aqui" @keyup.enter="sendMsg" />
    </div>

    <v-scale-transition>
      <v-btn fab v-show="!showChat && scrollYPos > 60" dark fixed bottom right color="secondary" @click="toTop">
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
    <foote />
  </v-app>
</template>

<script>
import axios from 'axios';
import navigation from "./components/Navigation";
import foote from "./components/Footer";
import home from "./components/HomeSection";
import about from "./components/AboutSection";
import download from "./components/DownloadSection";
import pricing from "./components/PricingSection";
import contact from "./components/ContactSection";

export default {
  name: "App",

  components: {
    navigation,
    foote,
    home,
    about,
    download,
    pricing,
    contact,
  },

  data: () => ({
    color: "",
    flat: null,
    collabearMsg: "Olá sou CollaBear, em que posso ajudar?",
    showChat: false,
    showBubble: true,
    scrollYPos: 0,
    userInput: "",
  }),

  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }

    window.addEventListener('scroll', () => {
      this.scrollYPos = Math.round(window.pageYOffset);
    })
  },

  methods: {
    toTop() {
      this.$vuetify.goTo(0);
    },

    createChatBubble(text) {
      const bubble = document.createElement('div');
      bubble.className = 'chat-bubble';
      bubble.textContent = text;

      document.getElementById('chat-container').appendChild(bubble);
    },

    closeChatBubble() {
      this.showBubble = false;
      this.showChat = false;
    },

    sendMsg() {
      axios.post("https://teamcollab.net/chatbot/collabear/prompt", {
        message: this.userInput,
      }).then((resp) => {
        this.collabearMsg = resp.data.response
      }).catch((e) => {
        console.log("error", e);
      });
      this.userInput = "";
    }
  },
};
</script>

<style scoped>
.v-main {
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.collabear {
  z-index: 99;
  border-radius: 20%;
  position: fixed;
  bottom: 16px;
  left: 16px;
}

.collabear-img {
  z-index: 99;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.chat-bubble {
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  max-width: 500px;
  left: 70px;
  bottom: 105px;
}

.chat-bubble::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 10px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #ffffff;
  border-bottom: 0;
  border-left: 0;
  margin-left: -3px;
  margin-bottom: -10px;
}

.close-chat-bubble {
  background-color: #ffffff;
  color: #000000;
  border-radius: 50%;
  scale: 70%;
  top: -10px;
  right: -10px;
  position: absolute;
}

.response-bubble {
  z-index: 99;
  margin-top: 10px;
  position: fixed;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  max-width: 500px;
  left: 135px;
  bottom: 40px;
}

.response-bubble::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 10px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #ffffff;
  border-bottom: 0;
  border-right: 0;
  margin-right: -3px;
  margin-bottom: -10px;
}
</style>
